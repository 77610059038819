import Vue from 'vue'
import "../src/plugins/bootstrap-vue"
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import Confetti from 'vue-confetti'
import axios from './api-service'
import routes from "./routes"
import encryptStorage from './secure-storage';
import store from "./store"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import "../src/assets/scss/app.scss"
import MortgageEstimate from '../src/components/MortgageEstimate.vue'

Vue.component("MortgageEstimate", MortgageEstimate)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(Confetti)
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 8
})

const scrollBehavior = function () {
  return {
    x: 0,
    y: 0
  }
}

const router = new VueRouter({
  scrollBehavior,
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  let authenticated = encryptStorage.getItem('authorized');
  let defaultTabs = encryptStorage.getItem('default-tabs');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!authenticated || !defaultTabs.includes(to.name)) {
      if (store.getters.getProspectId && store.getters.getOrgId) {
        next({
          path: '/' + store.getters.getProspectId + '&' + store.getters.getOrgId,
        });
      } else {
        next({ path: "/" });
      }
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

Vue.config.devtools = false
Vue.config.productionTip = false

Vue.prototype.$http = axios;
Vue.prototype.$localStore = encryptStorage;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
